<template>
    <div class="view pa24">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="输入计划描述查询" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
                <el-button type="primary" @click="showAddModel">添加计划</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="remark" align="center" label="计划描述" show-overflow-tooltip />
                <el-table-column prop="pointNum" align="center" width="120" label="赠送积分" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div>
                            <div style="color: #67c23a">
                                +{{ scope.row.pointNum }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="startDateStr" align="center" width="180" label="赠送日期" show-overflow-tooltip />
                <el-table-column align="center" label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button style="color:#F56C6C" type="text" @click="delData(scope.row)">取消计划
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="500px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="78px">
                <el-form-item label="赠送日期" prop="startDate">
                    <el-date-picker v-model="formData.startDate" value-format="yyyy-MM-dd" type="date"
                        placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="赠送积分" prop="pointNum">
                    <el-input-number v-model="formData.pointNum" :min="1" label="描述文字"></el-input-number>
                </el-form-item>
                <el-form-item label="计划描述" prop="remark">
                    <el-input v-model="formData.remark" type="textarea" rows="5" placeholder="请输入计划描述"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate()" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getMemberPointProjectList, setMemberPointProject, delMemberPointProject } from "@/api/walletRecords";
export default {
    name: "sendCardsTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            accountType: "9",
            //添加/编辑配置
            loadingBtn: false,
            formData: {
                startDate: "",
                pointNum: 0,
                remark: ""
            },
            formType: "",
            updateId: 0,
            modelTitle: "",
            associationId:"",
            rules: {
                startDate: {
                    required: true,
                    trigger: "change",
                    message: "请选择赠送日期",
                },
                remark: {
                    required: true,
                    trigger: "blur",
                    message: "请输入计划描述",
                },
            },
        };
    },
    created() {
        this.associationId = JSON.parse(sessionStorage.getItem('checkedAssociation')).associationId;
        this.getList();
    },
    computed: {
        watchAssociation() {
            return this.$store.state.checkedAssociation
        }
    },
    watch: {
        watchAssociation(row) {
            this.associationId = row.associationId;
            this.searchFun();
        },
    },
    methods: {
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1;
            this.getList();
        },
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.formData,
                        associationId:this.associationId
                    };
                    this.loadingBtn = true;
                    let message = "添加完成";
                    if (this.formType == 'update') {
                        params.pId = this.updateId;
                        message = "编辑完成"
                    }
                    setMemberPointProject(params).then((res) => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message.success(message);
                            this.showModel = false;
                            this.getList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                    });
                }
            });
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                associationId: this.associationId,
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                remark: this.searchData.name
            };
            try {
                this.loading = true;
                let result = await getMemberPointProjectList(params);
                this.loading = false;

                const { data } = result;
                let tableData = [];
                let payType = {
                    0: "消费",
                    1: "充值"
                }
                for (let row of data.pageInfo.list) {
                    row.payTypeName = payType[row.payType]
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增计划";
            this.formType = "add";
            this.formData = {
                startDate: new Date(),
                pointNum: 0,
                remark: ""
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} val - 当前点击行的值
         */
        showUpdateModel(val) {
            this.showModel = true;
            this.modelTitle = "编辑计划";
            this.formType = "update";
            this.updateId = val.pid;
            this.formData = {
                startDate: val.startDateStr,
                pointNum: val.pointNum,
                remark: val.remark
            };
        },
        /**@method 取消计划 */
        delData(row) {
            this.$confirm("确定取消该计划吗？", "取消计划", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(()=>{
                delMemberPointProject({pId:row.pid}).then(res => {
                    if (res.code === 200) {
                        this.$message.success("取消完成");
                        this.getList();
                    } else {
                        this.$message.error(res.message);
                    }
                })
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>